import cx from 'clsx';
import React, { memo } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import AlertMaterial from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

export interface Props {
  children: React.ReactNode;
  severity: 'error' | 'success' | 'warning' | 'info';
  onClose: () => void;
  className?: string;
}

const Alert: React.FC<Props> = ({ children, severity, onClose, className = '' }) => {
  const classes = useStyles();

  const alertClasses = cx(
    classes.alertRoot,
    className,
    severity ? `${classes[severity]} alert-${severity}` : '',
    'alert',
  );
  return (
    <AlertMaterial
      onClick={onClose}
      classes={{
        root: alertClasses,
      }}
      onClose={onClose}
      variant="outlined"
      severity={severity}
      iconMapping={{
        error: <ErrorIcon fontSize="inherit" />,
        success: <CheckIcon fontSize="inherit" />,
        warning: <ErrorIcon fontSize="inherit" />,
        info: <InfoIcon fontSize="inherit" />,
      }}
    >
      <div>{children}</div>
    </AlertMaterial>
  );
};

export default memo(Alert);
