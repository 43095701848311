import { useMutation, MutationOptions } from 'react-query';
import { AxiosPromise, AxiosResponse } from 'axios';
import { AxiosError } from 'types';

export default function useAsyncQuery<T = any>(
  fetcher: (...args: any) => AxiosPromise,
  config: MutationOptions<AxiosResponse<T>, any, AxiosError> = {},
) {
  return useMutation<AxiosResponse<T>, Partial<T>, AxiosError>(fetcher, {
    ...config,
  });
}
