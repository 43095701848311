const SocketURL = process.env.REACT_APP_API_URL?.replace('http', 'ws');

export enum NOTIFICATIONS_CONFIG_TYPE {
  REVIEWS = 1,
}

export enum NOTIFICATIONS_CONFIG_SUB_TYPE {
  NEW = 0,
}

export enum TRANSLATE_REVIEWS_LANGUAGE {
  NONE = 'Display Original',
  ENGLISH = 'English',
  GERMAN = 'German',
  SPANISH = 'Spanish',
}

export const SOCKET_CONFIG = {
  url: `${SocketURL}`,
  options: {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  },
} as const;
